import { Checkbox, Form, Input } from 'antd';
import React, { memo, useEffect, useState } from 'react';

import Name from '../../Inputs/Name';
import Password from '../../Inputs/Password';
import Phone from '../../Inputs/Phone';
import RePassword from '../../Inputs/RePassword';
import PasswordRules from '../../PasswordRules/PasswordRules';

const RegistrationForm = ({ form, isLoading, setChecked }) => {
  return (
    <>
      <Form form={form} layout='vertical' name='form_in_modal'>
        <Name />
        <Phone />
        <Password isLoading={isLoading} />
        <small className='form-text'>
          <PasswordRules />
        </small>
        <RePassword isLoading={isLoading} />
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Checkbox onChange={(v) => setChecked(v.target.checked)} />
          <div>
            <a
              target='_blank'
              href='https://kpkg-rezerv.ru/oficialnye-dokumenty/soglasie-na-obrabotku-personalnyh-dannyh.html'
            >
              Я соглашаюсь с условиями обработки и использования моих персональных данных
            </a>
            {' и '}
            <a
              target='_blank'
              href='https://kpkg-rezerv.ru/oficialnye-dokumenty/politika-v-otnoshenii-obrabotki-i-zashhity-personalnyh-dannyh-kpkg-rezerv.html'
            >
              Политикой в отношении обработки и защиты персональных данных КПКГ «Резерв»
            </a>
          </div>
        </div>
      </Form>
    </>
  );
};

export default memo(RegistrationForm);

